import React from 'react'
import classNames from 'classnames'

const BackgroundSection = ({ className, children }) => (
  <section className={classNames(className, 'background full-width')}>
    {children}

    <svg
      className="wave full-width"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1441.169 102.353"
      preserveAspectRatio="none"
    >
      <path d="M0 40.968c199.83 30.683 548.775 76.34 753.199 56.585 132.347-12.79 197.084-34.71 266.148-47.583 86.577-16.138 179.93-18.07 421.822 30.03V0H0v40.968" />
    </svg>
  </section>
)

export default BackgroundSection
