import React, { useState } from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImg from 'gatsby-image'

const imagesQuery = graphql`
  query IndexImagesQuery {
    featureModernTestingPlatformBackground: file(
      relativePath: { eq: "features/modern-testing-platform-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 575) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureModernTestingPlatformLayer1: file(
      relativePath: { eq: "features/modern-testing-platform-layer1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 287) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureModernTestingPlatformLayer2: file(
      relativePath: { eq: "features/modern-testing-platform-layer2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 265) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureModernTestingPlatformLayer3: file(
      relativePath: { eq: "features/modern-testing-platform-layer3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 471) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    featureCiVisibilityBackground: file(
      relativePath: { eq: "features/ci-visibility-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 951) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureCiVisibilityLayer1: file(
      relativePath: { eq: "features/ci-visibility-layer1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 304) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureCiVisibilityLayer2: file(
      relativePath: { eq: "features/ci-visibility-layer2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 475) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureCiVisibilityLayer3: file(
      relativePath: { eq: "features/ci-visibility-layer3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 532) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureFlakyTestManagementBackground: file(
      relativePath: { eq: "features/flaky-test-management-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 575) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureFlakyTestManagementLayer1: file(
      relativePath: { eq: "features/flaky-test-management-layer1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 384) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureFlakyTestManagementLayer2: file(
      relativePath: { eq: "features/flaky-test-management-layer2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 109) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureFlakyTestManagementLayer3: file(
      relativePath: { eq: "features/flaky-test-management-layer3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 311) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureFlakyTestManagementLayer4: file(
      relativePath: { eq: "features/flaky-test-management-layer4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 133) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureIntelligentTestRunnerBackground: file(
      relativePath: { eq: "features/intelligent-test-runner-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 575) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureIntelligentTestRunnerLayer1: file(
      relativePath: { eq: "features/intelligent-test-runner-layer1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 254) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureIntelligentTestRunnerLayer2: file(
      relativePath: { eq: "features/intelligent-test-runner-layer2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 155) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureDashboardsBackground: file(
      relativePath: { eq: "features/dashboards-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 575) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureDashboardsLayer1: file(
      relativePath: { eq: "features/dashboards-layer1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 362) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureDashboardsLayer2: file(
      relativePath: { eq: "features/dashboards-layer2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 276) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureDashboardsLayer3: file(
      relativePath: { eq: "features/dashboards-layer3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 310) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    featureDashboardsLayer4: file(
      relativePath: { eq: "features/dashboards-layer4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 258) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    integrationsGithub: file(
      relativePath: { eq: "integrations/github-image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 491) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    integrationsNativeApps: file(
      relativePath: { eq: "integrations/native-apps-image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 490) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    integrationsSlackEmail: file(
      relativePath: { eq: "integrations/slack-email-image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 491) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityTestAggregation: file(
      relativePath: { eq: "ci-visibility/test-aggregation.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 574) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityDistributedTracing: file(
      relativePath: { eq: "ci-visibility/distributed-tracing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityLogsEvents: file(
      relativePath: { eq: "ci-visibility/logs-events.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityExceptions: file(
      relativePath: { eq: "ci-visibility/exceptions.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityCodePath: file(
      relativePath: { eq: "ci-visibility/code-path.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityTestCode: file(
      relativePath: { eq: "ci-visibility/test-code.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityHistory: file(
      relativePath: { eq: "ci-visibility/history.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ciVisibilityPerformance: file(
      relativePath: { eq: "ci-visibility/performance.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    intelligentTestRunnerCutCosts: file(
      relativePath: { eq: "intelligent-test-runner/cut-costs.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    intelligentTestRunnerBranches: file(
      relativePath: { eq: "intelligent-test-runner/branches.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 443) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    intelligentTestRunnerRetries: file(
      relativePath: { eq: "intelligent-test-runner/retries.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 443) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    flakyTestManagementManaging: file(
      relativePath: { eq: "flaky-test-management/managing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    flakyTestManagementFixing: file(
      relativePath: { eq: "flaky-test-management/fixing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    flakyTestManagementInsights: file(
      relativePath: { eq: "flaky-test-management/insights.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 950) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const Img = ({ id }) => {
  const imagesData = useStaticQuery(imagesQuery)
  if (!imagesData[id]) {
    console.log('>>>', id)
  }
  const { fluid } = imagesData[id].childImageSharp

  const [loaded, setLoaded] = useState(false)

  return (
    <GatsbyImg
      onLoad={() => setLoaded(true)}
      className={classNames('img', { loaded })}
      durationFadeIn={300}
      alt=""
      fluid={fluid}
    />
  )
}

export default Img
